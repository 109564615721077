import React from 'react';
import bemCn from 'bem-cn';
import './style.scss';

const b = bemCn('hr');

const SnifHr = ({ fat, w100 = false }) => {
    return <hr className={b({ fat: fat }).mix(`${w100 ? '' : 'mx-2 mx-md-4'}`)} />;
};

export default SnifHr;
