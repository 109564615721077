export const states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
];

export const statesWithCanada = states.concat([
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon',
]);

export const NEARBY_STATES = {
    alabama: ['Florida', 'Georgia', 'Mississippi', 'Tennessee'],
    arizona: ['California', 'Colorado', 'Nevada', 'New Mexico', 'Utah'],
    arkansas: ['Louisiana', 'Mississippi', 'Missouri', 'Oklahoma', 'Tennessee', 'Texas'],
    california: ['Arizona', 'Nevada', 'Oregon'],
    colorado: ['Arizona', 'Kansas', 'Nebraska', 'New Mexico', 'Oklahoma', 'Utah', 'Wyoming'],
    connecticut: ['Massachusetts', 'New York', 'Rhode Island'],
    delaware: ['Maryland', 'New Jersey', 'Pennsylvania'],
    florida: ['Alabama', 'Georgia'],
    georgia: ['Alabama', 'Florida', 'North Carolina', 'South Carolina', 'Tennessee'],
    idaho: ['Montana', 'Nevada', 'Oregon', 'Utah', 'Washington', 'Wyoming'],
    illinois: ['Indiana', 'Iowa', 'Michigan', 'Kentucky', 'Missouri', 'Wisconsin'],
    indiana: ['Illinois', 'Kentucky', 'Michigan', 'Ohio'],
    iowa: ['Illinois', 'Minnesota', 'Missouri', 'Nebraska', 'South Dakota', 'Wisconsin'],
    kansas: ['Colorado', 'Missouri', 'Nebraska', 'Oklahoma'],
    kentucky: ['Illinois', 'Indiana', 'Missouri', 'Ohio', 'Tennessee', 'Virginia', 'West Virginia'],
    louisiana: ['Arkansas', 'Mississippi', 'Texas'],
    maine: ['New Hampshire'],
    maryland: ['Delaware', 'Pennsylvania', 'Virginia', 'West Virginia'],
    massachusetts: ['Connecticut', 'New Hampshire', 'New York', 'Rhode Island', 'Vermont'],
    michigan: ['Illinois', 'Indiana', 'Minnesota', 'Ohio', 'Wisconsin'],
    minnesota: ['Iowa', 'Michigan', 'North Dakota', 'South Dakota', 'Wisconsin'],
    mississippi: ['Alabama', 'Arkansas', 'Louisiana', 'Tennessee'],
    missouri: ['Arkansas', 'Illinois', 'Iowa', 'Kansas', 'Kentucky', 'Nebraska', 'Oklahoma', 'Tennessee'],
    montana: ['Idaho', 'North Dakota', 'South Dakota', 'Wyoming'],
    nebraska: ['Colorado', 'Iowa', 'Kansas', 'Missouri', 'South Dakota', 'Wyoming'],
    nevada: ['Arizona', 'California', 'Idaho', 'Oregon', 'Utah'],
    'new-hampshire': ['Maine', 'Massachusetts', 'Vermont'],
    'new-jersey': ['Delaware', 'New York', 'Pennsylvania'],
    'new-mexico': ['Arizona', 'Colorado', 'Oklahoma', 'Texas', 'Utah'],
    'new-york': ['Connecticut', 'Massachusetts', 'New Jersey', 'Pennsylvania', 'Rhode Island', 'Vermont'],
    'north-carolina': ['Georgia', 'South Carolina', 'Tennessee', 'Virginia'],
    'north-dakota': ['Minnesota', 'Montana', 'South Dakota'],
    ohio: ['Indiana', 'Kentucky', 'Michigan', 'Pennsylvania', 'West Virginia'],
    oklahoma: ['Arkansas', 'Colorado', 'Kansas', 'Missouri', 'New Mexico', 'Texas'],
    oregon: ['California', 'Idaho', 'Nevada', 'Washington'],
    pennsylvania: ['Delaware', 'Maryland', 'New Jersey', 'New York', 'Ohio', 'West Virginia'],
    'rhode-island': ['Connecticut', 'Massachusetts', 'New York'],
    'south-carolina': ['Georgia', 'North Carolina'],
    'south-dakota': ['Iowa', 'Minnesota', 'Montana', 'Nebraska', 'North Dakota', 'Wyoming'],
    tennessee: ['Alabama', 'Arkansas', 'Georgia', 'Kentucky', 'Mississippi', 'Missouri', 'North Carolina', 'Virginia'],
    texas: ['Arkansas', 'Louisiana', 'New Mexico', 'Oklahoma'],
    utah: ['Arizona', 'Colorado', 'Idaho', 'Nevada', 'New Mexico', 'Wyoming'],
    vermont: ['Massachusetts', 'New Hampshire', 'New York'],
    virginia: ['Kentucky', 'Maryland', 'North Carolina', 'Tennessee', 'West Virginia'],
    washington: ['Idaho', 'Oregon'],
    'west-virginia': ['Kentucky', 'Maryland', 'Ohio', 'Pennsylvania', 'Virginia'],
    wisconsin: ['Illinois', 'Iowa', 'Michigan', 'Minnesota'],
    wyoming: ['Colorado', 'Idaho', 'Montana', 'Nebraska', 'South Dakota', 'Utah'],
};
