import React, { useEffect, useState } from 'react';
import { RouteFormatter } from '../../routes';
import dogFencedImg from '../../assets/img/spot-types/dog-fully-fenced.png';
import dogHikingImg from '../../assets/img/spot-types/dog-hiking.png';
import dogIndoorImg from '../../assets/img/spot-types/dog-indoor.png';
import dogWaterImg from '../../assets/img/spot-types/dog-water-park.png';
import dogBeachImg from '../../assets/img/spot-types/dog-beach.png';
import dogFieldImg from '../../assets/img/spot-types/dog-field.png';
import dogSmallImg from '../../assets/img/spot-types/dog-small.png';
import dogAgiImg from '../../assets/img/spot-types/dog-agility.png';
import PictureSet from '../../components/picture-set';
import {
    SvgAmenitiesAgilityEquipment,
    SvgAmenitiesBeach,
    SvgAmenitiesField,
    SvgAmenitiesHill,
    SvgAmenitiesIndoor,
    SvgAmenitiesKiddiePool,
    SvgAmenitiesSmallDog,
} from '../../components/svg/amenities';
import SvgFenced from 'src/assets/svg/SvgFenced.svg';
import SvgSrArrow from 'src/assets/svg/SvgSrArrow.svg';
import './render-other-types.scss';

export const otherSubs = [
    {
        id: 'WATER',
        icon: <SvgAmenitiesKiddiePool />,
        img: dogWaterImg,
        title: 'Dog water parks',
        desc: 'Many hosts have added water features like pools and/or are nearby lakes or rivers.',
        link: RouteFormatter.waterListings(),
    },
    {
        id: 'HIKING',
        icon: <SvgAmenitiesHill />,
        img: dogHikingImg,
        title: 'Dog hiking trails',
        desc: 'Some of our best spots have hiking trails in wonderful natural settings.',
        link: RouteFormatter.hikingListings(),
    },
    {
        id: 'INDOOR',
        icon: <SvgAmenitiesIndoor />,
        img: dogIndoorImg,
        title: 'Indoor dog parks',
        desc: 'A good alternative to do activities with your dogs on a rainy day and unpleasant weather in general.',
        link: RouteFormatter.indoorListings(),
    },
    {
        id: 'FENCED',
        icon: <SvgFenced />,
        img: dogFencedImg,
        title: 'Fully fenced dog parks',
        desc: 'Very popular choice, perfect to contain your dogs if they are escape artists or if they are not good at recall.',
        link: RouteFormatter.fencedListings(),
    },
    {
        id: 'BEACH',
        icon: <SvgAmenitiesBeach />,
        img: dogBeachImg,
        title: 'Dog beaches',
        desc: 'Some spots are conveniently located by the sea or have sand and other beach features.',
        link: RouteFormatter.beachListings(),
    },
    {
        id: 'AGILITY',
        icon: <SvgAmenitiesAgilityEquipment />,
        img: dogAgiImg,
        title: 'Dog agility parks',
        desc: 'Some hosts have implemented full agility courses to train your dogs in a private environment.',
        link: RouteFormatter.agilityListings(),
    },
    {
        id: 'FIELDS',
        icon: <SvgAmenitiesField />,
        img: dogFieldImg,
        title: 'Dog fields',
        desc: 'Many of the listed spots are large pastures, fields and have farm animals that can be sighted.',
        link: RouteFormatter.fieldsListings(),
    },
    {
        id: 'SMALL',
        icon: <SvgAmenitiesSmallDog />,
        img: dogSmallImg,
        title: 'Small dog parks',
        desc: 'Dog parks that specifically work for the needs of dogs that are smaller in size.',
        link: RouteFormatter.smallDogListings(),
    },
];

const otherSubsSeoCity = (city) => {
    const { subpages } = city;
    const subWaterPage = subpages.find((s) => s.pageType === 'DOG_WATER_PARKS');
    const subFencedPage = subpages.find((s) => s.pageType === 'FULLY_FENCED_DOG_PARKS');
    const subHikingPage = subpages.find((s) => s.pageType === 'DOG_HIKING_TRAILS');
    const subAgilityPage = subpages.find((s) => s.pageType === 'DOG_AGILITY_PARKS');
    const subFieldPage = subpages.find((s) => s.pageType === 'DOG_FIELDS');
    const subSmallPage = subpages.find((s) => s.pageType === 'SMALL_DOG_PARKS');
    const subBeachPage = subpages.find((s) => s.pageType === 'DOG_BEACHES');
    const subIndoorPage = subpages.find((s) => s.pageType === 'INDOOR_DOG_PARKS');

    return [
        {
            id: 'WATER',
            icon: <SvgAmenitiesKiddiePool />,
            img: dogWaterImg,
            title: 'Dog water parks',
            desc: 'Many hosts have added water features like pools and/or are nearby lakes or rivers.',
            noFollow: !subWaterPage,
            link: subWaterPage
                ? RouteFormatter.listings({ first: subWaterPage.url })
                : encodeURI(
                      RouteFormatter.waterListings(null, {
                          query: {
                              loc: `${city.name}, ${city.state}`,
                              lat: city.latitude || undefined,
                              lng: city.longitude || undefined,
                          },
                      })
                  ),
            slug: 'DOG_WATER_PARKS',
        },
        {
            id: 'HIKING',
            icon: <SvgAmenitiesHill />,
            img: dogHikingImg,
            title: 'Dog hiking trails',
            desc: 'Some of our best spots have hiking trails in wonderful natural settings.',
            noFollow: !subHikingPage,
            link: subHikingPage
                ? RouteFormatter.listings({ first: subHikingPage.url })
                : encodeURI(
                      RouteFormatter.hikingListings(null, {
                          query: {
                              loc: `${city.name}, ${city.state}`,
                              lat: city.latitude || undefined,
                              lng: city.longitude || undefined,
                          },
                      })
                  ),
            slug: 'DOG_HIKING_TRAILS',
        },
        {
            id: 'INDOOR',
            icon: <SvgAmenitiesIndoor />,
            img: dogIndoorImg,
            title: 'Indoor dog parks',
            desc: 'A good alternative to do activities with your dogs on a rainy day and unpleasant weather in general.',
            noFollow: !subIndoorPage,
            link: subIndoorPage
                ? RouteFormatter.listings({ first: subIndoorPage.url })
                : encodeURI(
                      RouteFormatter.indoorListings(null, {
                          query: {
                              loc: `${city.name}, ${city.state}`,
                              lat: city.latitude || undefined,
                              lng: city.longitude || undefined,
                          },
                      })
                  ),
            slug: 'INDOOR_DOG_PARKS',
        },
        {
            id: 'FENCED',
            icon: <SvgFenced />,
            img: dogFencedImg,
            title: 'Fully fenced dog parks',
            desc: 'Very popular choice, perfect to contain your dogs if they are escape artists or if they are not good at recall.',
            noFollow: !subFencedPage,
            link: subFencedPage
                ? RouteFormatter.listings({ first: subFencedPage.url })
                : encodeURI(
                      RouteFormatter.fencedListings(null, {
                          query: {
                              loc: `${city.name}, ${city.state}`,
                              lat: city.latitude || undefined,
                              lng: city.longitude || undefined,
                          },
                      })
                  ),
            slug: 'FULLY_FENCED_DOG_PARKS',
        },
        {
            id: 'BEACH',
            icon: <SvgAmenitiesBeach />,
            img: dogBeachImg,
            title: 'Dog beaches',
            desc: 'Some spots are conveniently located by the sea or have sand and other beach features.',
            noFollow: !subBeachPage,
            link: subBeachPage
                ? RouteFormatter.listings({ first: subBeachPage.url })
                : encodeURI(
                      RouteFormatter.beachListings(null, {
                          query: {
                              loc: `${city.name}, ${city.state}`,
                              lat: city.latitude || undefined,
                              lng: city.longitude || undefined,
                          },
                      })
                  ),
            slug: 'DOG_BEACHES',
        },
        {
            id: 'AGILITY',
            icon: <SvgAmenitiesAgilityEquipment />,
            img: dogAgiImg,
            title: 'Dog agility parks',
            desc: 'Some hosts have implemented full agility courses to train your dogs in a private environment.',
            noFollow: !subAgilityPage,
            link: subAgilityPage
                ? RouteFormatter.listings({ first: subAgilityPage.url })
                : encodeURI(
                      RouteFormatter.agilityListings(null, {
                          query: {
                              loc: `${city.name}, ${city.state}`,
                              lat: city.latitude || undefined,
                              lng: city.longitude || undefined,
                          },
                      })
                  ),
            slug: 'DOG_AGILITY_PARKS',
        },
        {
            id: 'FIELDS',
            icon: <SvgAmenitiesField />,
            img: dogFieldImg,
            title: 'Dog fields',
            desc: 'Many of the listed spots are large pastures, fields and have farm animals that can be sighted.',
            noFollow: !subFieldPage,
            link: subFieldPage
                ? RouteFormatter.listings({ first: subFieldPage.url })
                : encodeURI(
                      RouteFormatter.fieldsListings(null, {
                          query: {
                              loc: `${city.name}, ${city.state}`,
                              lat: city.latitude || undefined,
                              lng: city.longitude || undefined,
                          },
                      })
                  ),
            slug: 'DOG_FIELDS',
        },
        {
            id: 'SMALL',
            icon: <SvgAmenitiesSmallDog />,
            img: dogSmallImg,
            title: 'Small dog parks',
            desc: 'Dog parks that specifically work for the needs of dogs that are smaller in size.',
            noFollow: !subSmallPage,
            link: subSmallPage
                ? RouteFormatter.listings({ first: subSmallPage.url })
                : encodeURI(
                      RouteFormatter.smallDogListings(null, {
                          query: {
                              loc: `${city.name}, ${city.state}`,
                              lat: city.latitude || undefined,
                              lng: city.longitude || undefined,
                          },
                      })
                  ),
            slug: 'SMALL_DOG_PARKS',
        },
    ];
};

const RenderOtherTypes = ({
    city,
    subObj,
    getSpecificSpots,
    seoSubCityType,
    SSR,
    title = '',
    sub = true,
    hostPage,
}) => {
    const [listClient, setListClient] = useState([]);
    const myTitle = title || 'What types of private dog parks are available?';
    let listSSR = [];

    if (SSR) {
        if (subObj) {
            if (seoSubCityType) {
                listSSR = otherSubsSeoCity(city).filter((e) => e.id !== subObj.id);
            } else {
                listSSR = otherSubs.filter((e) => e.id !== subObj.id);
            }
        } else {
            if (seoSubCityType) {
                listSSR = otherSubsSeoCity(city);
            } else {
                listSSR = otherSubs;
            }
        }
    }

    const list = SSR ? listSSR : listClient;

    const handleExplore = (e, item) => {
        if (seoSubCityType) {
            e.preventDefault();
            getSpecificSpots(item);
            return;
        }
        if (!item.link) {
            e.preventDefault();
            getSpecificSpots(item.page);
        }
    };

    useEffect(() => {
        if (!subObj) {
            let filtered = [];
            if (seoSubCityType) {
                filtered = otherSubsSeoCity(city);
            } else {
                filtered = otherSubs;
            }
            setListClient(filtered);
            return;
        }
        let filtered = [];
        if (seoSubCityType) {
            filtered = otherSubsSeoCity(city).filter((e) => e.id !== subObj.id);
        } else {
            filtered = otherSubs.filter((e) => e.id !== subObj.id);
        }
        setListClient(filtered);
    }, [subObj]);

    return (
        <div className="sniff-other-types">
            <div className="l-container">
                <h2 className="snif-m1 snif-l1-pc snif-semibold text-dark mb-4">{myTitle}</h2>
                {sub && (
                    <p className="snif-p text-dark mb-5 d-none d-md-block">
                        Sniffspot has different types of private dog parks to allow any dog to find their ideal spot!
                    </p>
                )}
                <div className="other-types-container">
                    {hostPage
                        ? list.map((item, idx) => (
                              <div key={idx} className="other-types-item">
                                  <PictureSet
                                      imgs={item.img}
                                      width={582}
                                      height={400}
                                      alt={`${item.title} thumbnail`}
                                  />
                                  <div className="other-types-item-r">
                                      <p className="snif-p snif-m1-pc snif-semibold text-dark">{item.title}</p>
                                  </div>
                              </div>
                          ))
                        : list.map((item, idx) => (
                              <a
                                  key={idx}
                                  className="other-types-item"
                                  href={item.link || RouteFormatter.listings({})}
                                  onClick={(e) => handleExplore(e, item)}
                                  rel={item.noFollow ? 'nofollow' : undefined}
                              >
                                  <PictureSet
                                      imgs={item.img}
                                      width={582}
                                      height={400}
                                      alt={`${item.title} thumbnail`}
                                  />
                                  <div className="other-types-item-r">
                                      <div>
                                          <p className="snif-p snif-semibold text-dark mb-1">{item.title}</p>
                                          <p className="snif-p snif-regular text-deep mb-2">{item.desc}</p>
                                      </div>
                                      <p className="types-item-r-link snif-p">
                                          Explore
                                          <SvgSrArrow />
                                      </p>
                                  </div>
                              </a>
                          ))}
                </div>
            </div>
        </div>
    );
};

export default RenderOtherTypes;
