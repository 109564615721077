import React from 'react';
import gPlayImg from '../../assets/img/google-play.png';
import { appleStoreLink, googlePlayLinkUrl } from '../../consts/link.consts';
import PictureSet from '../picture-set';
import SvgAppleStore from 'src/assets/svg/SvgAppleStore.svg';

const MobileAppDownload = () => {
    return (
        <>
            <a href={appleStoreLink} target="_blank" rel="noopener noreferrer">
                <SvgAppleStore width={196} height={58} />
            </a>
            <a href={googlePlayLinkUrl} target="_blank" rel="noopener noreferrer">
                <PictureSet imgs={gPlayImg} width={196} height={58} alt="Google Play Link" />
            </a>
        </>
    );
};

export default MobileAppDownload;
