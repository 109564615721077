import bemCn from 'bem-cn';
import React from 'react';
import qrCodeImg from '../../assets/img/sniffspot-qr-code.png';
import sniffAppDesktopImg from '../../assets/img/sniffspot-app-desktop-ss.png';
import MobileAppDownload from '../mobile-app-download';
import PictureSet from '../picture-set';
import './style.scss';

const b = bemCn('render-app-download');

const RenderAppDownload = () => (
    <div className={b()}>
        <div>
            <h3 className={b('title').mix('d-md-none')}>Download Sniffspot iOS and Android Apps</h3>
            <h3 className={b('title').mix('d-none d-md-block')}>Download the Sniffspot app</h3>
            <div className={b('logos-container')}>
                <PictureSet imgs={qrCodeImg} width={156} height={156} alt="Sniffspot app qrcode" />
                <div className={b('logos')}>
                    <MobileAppDownload />
                </div>
            </div>
        </div>
        <div className="d-none d-md-block">
            <PictureSet imgs={sniffAppDesktopImg} width={491} height={432} alt="Sniffspot app screenshot" />
        </div>
    </div>
);

export default RenderAppDownload;
