import React from 'react';
import bemCn from 'bem-cn';
import { Splide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import './style.scss';

const b = bemCn('slide-mobile');

const SlideMobile = ({ children, opt = {} }) => {
    return (
        <div className={b()}>
            <Splide options={{ focus: 'center', padding: '16px', gap: '8px', arrows: false, ...opt }}>
                {children}
            </Splide>
        </div>
    );
};

export default SlideMobile;
